
<template>
  <div>
    <el-dialog v-bind="$attrs" v-on="$listeners"  @close="onClose"
    title="编辑电子面单"
    :visible.sync="visible"
    width="740px"
    >

      <el-form ref="form" :model="row" :rules="rules" size="mini" label-width="120px"      >
<el-row>
        
        <el-col :span="12">
                <el-form-item label="面单名称" prop="name">
              <el-input v-model="row.name" placeholder="请输入面单名称" clearable
                :disabled="false"
                :style="{width: '200px'}"></el-input>
                </el-form-item>
        </el-col>
        
        <el-col :span="12">
                <el-form-item label="类型" prop="type">
                <el-select v-model="row.type"
                :disabled="false"
                clearable
                placeholder="请选择类型">
                    <el-option
                          v-for="item in enums['WmsSheetTypeEnum']"
                          :key="item.value"
                          :label="item.name"
                          :value="item.value">
                        </el-option>
                </el-select>
                </el-form-item>
        </el-col>
            </el-row>
            <el-row>
        
        <el-col :span="12">
                <el-form-item label="月结账号" prop="partnerId">
              <el-input v-model="row.partnerId" placeholder="请输入月结账号" clearable
                :disabled="false"
                :style="{width: '200px'}"></el-input>
                </el-form-item>
        </el-col>
        
        <el-col :span="12">
                <el-form-item label="账号密码" prop="partnerKey">
              <el-input v-model="row.partnerKey" placeholder="请输入账号密码" clearable
                :disabled="false"
                :style="{width: '200px'}"></el-input>
                </el-form-item>
        </el-col>
            </el-row>
            <el-row>
        
        <el-col :span="12">
                <el-form-item label="秘钥" prop="partnerSecret">
              <el-input v-model="row.partnerSecret" placeholder="请输入秘钥" clearable
                :disabled="false"
                :style="{width: '200px'}"></el-input>
                </el-form-item>
        </el-col>
        
        <el-col :span="12">
                <el-form-item label="客户账户" prop="partnerName">
              <el-input v-model="row.partnerName" placeholder="请输入客户账户" clearable
                :disabled="false"
                :style="{width: '200px'}"></el-input>
                </el-form-item>
        </el-col>
            </el-row>
            <el-row>
        
        <el-col :span="12">
                <el-form-item label="网点名称" prop="net">
              <el-input v-model="row.net" placeholder="请输入网点名称" clearable
                :disabled="false"
                :style="{width: '200px'}"></el-input>
                </el-form-item>
        </el-col>
        
        <el-col :span="12">
                <el-form-item label="网点编号" prop="code">
              <el-input v-model="row.code" placeholder="请输入网点编号" clearable
                :disabled="false"
                :style="{width: '200px'}"></el-input>
                </el-form-item>
        </el-col>
            </el-row>
            <el-row>
        
        <el-col :span="12">
                <el-form-item label="快递公司" prop="compony">
              <el-input v-model="row.compony" placeholder="请输入快递公司" clearable
                :disabled="false"
                :style="{width: '200px'}"></el-input>
                </el-form-item>
        </el-col>
        
        <el-col :span="12">
                <el-form-item label="快递编码" prop="com">
              <el-input v-model="row.com" placeholder="请输入快递编码" clearable
                :disabled="false"
                :style="{width: '200px'}"></el-input>
                </el-form-item>
        </el-col>
            </el-row>
            <el-row>
        </el-row>

      </el-form>
      <div slot="footer">
        <el-button @click="close">取消</el-button>
        <el-button type="primary" @click="handleConfirm">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { getEnums, uploadFile } from "@/api/common"
import { editWmsSheet } from "@/api/wms/wmsSheet"


export default {
  inheritAttrs: false,
  components: { },
  props: [],
  data() {
    return {
      visible: false,
      row: {
            name: null,
            type: null,
            partnerId: null,
            partnerKey: null,
            partnerSecret: null,
            partnerName: null,
            net: null,
            code: null,
            compony: null,
            com: null,
      },
      rules: {
            name: [
                { required: true, message: '请输入面单名称!'},
            ],
            type: [
                { required: true, message: '请输入类型!'},
            ],
      },
        enums: {},
        enumMaps: {},
    }
  },
  computed: {},
  watch: {},
  created() {
    this.initEnums()
  },
  mounted() {},
  methods: {
      initEnums() {
          let names = []
          names.push('WmsSheetTypeEnum')
          const param = {
              names: names
          }
          getEnums(param).then(res=>{
              if(res.code===200) {
                this.enums = res.data.enums
                Object.keys(this.enums).forEach(key => {
                  let map = {}
                  this.enums[key].forEach(obj => map[obj.value] = obj.name)
                  this.enumMaps[key] = map
                })
              } else {
                  this.$message.error(res.message)
              }
          })
    },
    onOpen(row) {
        this.visible = true
        this.row = row
    },
    onClose() {
    },
    close() {
      this.$refs['form'].resetFields()
      this.visible = false
    },
    success(result) {
      this.$emit('success', result)
    },
    upload(param){
        const formData = new FormData()
        formData.append('file', param.file)
        uploadFile(formData).then(res => {
            if(res.code === 200){
                param.onSuccess(res.data.url)
                this.$message.success("上传成功")
            }else{
                this.$message.error(res.message)
            }
        }).catch(response => {
            console.log(response)
            this.$message.error('上传失败')
            param.onError()
        })
    },
    handleConfirm() {
      this.$refs['form'].validate(valid => {
        if (!valid) return;
        const param = this.row
        this.loading = true
        editWmsSheet(param).then(res=>{
            this.loading = false
            if(res.code===200) {
                this.$message.success(res.message)
                this.success(res.data)
                this.close()
            } else {
                this.$message.error(res.message)
            }
        }).catch(err=>{
            this.loading = false
        })
      })
    },
  }
}

</script>
<style scoped>
.el-select {
    width: 200px;
}
.avatar {
    max-width: 200px;
    max-height: 200px
}
</style>
