import request from '@/api/request.js'

/**
 * @Description: 电子面单
 */

// 分页查询
export function pageWmsSheet(data) {
    return request({
        url: '/api/wmsSheet/page',
        method: 'POST',
        data
    })
}

// 搜索过滤
export function listWmsSheet(data) {
    return request({
        url: '/api/wmsSheet/list',
        method: 'POST',
        data
    })
}

// 查看详情
export function detailWmsSheet(data) {
    return request({
        url: '/api/wmsSheet/detail',
        method: 'POST',
        data
    })
}

// 添加
export function addWmsSheet(data) {
    return request({
        url: '/api/wmsSheet/add',
        method: 'POST',
        data
    })
}

// 编辑
export function editWmsSheet(data) {
    return request({
        url: '/api/wmsSheet/edit',
        method: 'POST',
        data
    })
}

// 删除
export function delWmsSheet(data) {
    return request({
        url: '/api/wmsSheet/delete',
        method: 'POST',
        data
    })
}

// 批量删除
export function batchDeleteWmsSheet(data) {
    return request({
        url: '/api/wmsSheet/batchDelete',
        method: 'POST',
        data
    })
}

