<template>
  <div>
    <div class="contents">
      <submenu></submenu>
      <div class="tableSearch">
          <el-form :inline="true" ref="searchForm" :model="searchForm" label-width="90px">
                <el-form-item label="面单名称" style="margin-bottom:10px">

                  <el-input v-model="searchForm.name" clearable  style="width:190px"
                    placeholder="请输入面单名称"></el-input>
                </el-form-item>

                <span class="buttons">
                    <el-button @click="createSearch" type="primary" icon="el-icon-search" :loading="loading">查询</el-button>
                    <el-button @click="reset">重置</el-button>
                </span>
          </el-form>
      </div>

    <div class="mainbox">

     <vxe-toolbar ref="xToolbar" custom>
            <template #buttons>
              <el-button type="primary" class="tbBtn" icon="el-icon-plus" size="small" @click="handleAdd">添加</el-button>

                  <el-dropdown type="primary" class="tbBtn" @command="handleCommand">
                      <el-button type="primary" plain >
                        批量操作<i class="el-icon-arrow-down el-icon--right"></i>
                      </el-button>
                      <el-dropdown-menu slot="dropdown" >
                        <el-dropdown-item command="batchDelete">批量删除</el-dropdown-item>
                      </el-dropdown-menu>
                  </el-dropdown>
            </template>
            <template #tools>
            </template>
      </vxe-toolbar>

      <vxe-table border
        id="table"
        empty-text="没有更多数据"
        header-align="center"
        stripe
        :data="tableData"
        :custom-config="{storage: true, resizable: true}"
        :column-config="{resizable: true}"
        :row-config="{isCurrent: true, isHover: true, keyField: 'id'}"
        size="small"
        @checkbox-all="handleSelectionChange"
        @checkbox-change="handleSelectionChange"
         ref="table">
          <vxe-column type="checkbox" width="40" fixed></vxe-column>


        <vxe-column
            field="name"
            title="面单名称"
            min-width="120"
        >
          <template slot-scope="scope">
                {{scope.row.name}}
          </template>
        </vxe-column>
        <vxe-column
            field="type"
            title="类型"
            min-width="50"
        >
          <template slot-scope="scope">
                {{enumMaps['WmsSheetTypeEnum'] && enumMaps['WmsSheetTypeEnum'][scope.row.type]}}
          </template>
        </vxe-column>
        <vxe-column
            field="partnerId"
            title="月结账号"
            min-width="80"
        >
          <template slot-scope="scope">
                {{scope.row.partnerId}}
          </template>
        </vxe-column>
        <vxe-column
            field="net"
            title="网点名称"
            min-width="80"
        >
          <template slot-scope="scope">
                {{scope.row.net}}
          </template>
        </vxe-column>

        <vxe-column
            field="compony"
            title="快递公司"
            min-width="80"
        >
          <template slot-scope="scope">
                {{scope.row.compony}}
          </template>
        </vxe-column>
        <vxe-column
            field="createTime"
            title="添加时间"
            min-width="80"
        >
          <template slot-scope="scope">
                {{scope.row.createTime}}
          </template>
        </vxe-column>

        <vxe-column title="操作" fixed="right" width="180">
            <template slot-scope="scope">
                  <el-button class="c-button" @click="handleDetail(scope.row)" type="text" size="small">详情</el-button>

                  <el-button class="c-button" :disabled="scope.row.type == 0" @click="handleAuth(scope.row)" type="text" size="small">添加授权</el-button>

                  <el-button class="c-button" @click="handleEdit(scope.row)" type="text" size="small">编辑</el-button>

                  <el-popconfirm title="确定要删除吗？删除后不可恢复，请谨慎操作" @confirm="del(scope.row)">
                    <el-button class="c-button" slot="reference" type="text" size="small">删除</el-button>
                  </el-popconfirm>

             </template>
        </vxe-column>

      </vxe-table>

      <div class="paginations">
          <el-pagination
            background
            layout="total, prev, pager, next, sizes"
            :page-sizes="[20, 50, 100, 200, 400]"
            @current-change="changePage" :current-page="page" :page-size="pageSize" @size-change="handleSizeChange"
            :total="total">
          </el-pagination>
      </div>
      </div>
    </div>

    <sheet-add ref="wmsSheetAdd" @success="getTopSearch"></sheet-add>
    <sheet-detail ref="wmsSheetDetail" @success="getTopSearch"></sheet-detail>
    <sheet-edit ref="wmsSheetEdit" @success="getTopSearch"></sheet-edit>

  </div>
</template>

<script>
import Vue from 'vue'
import Clipboard from 'clipboard'
import passWord from "@/components/password"
import backImg from "@/assets/logofang.png"
import sheetAdd from "./wmsSheetAdd"
import sheetDetail from "./wmsSheetDetail"
import sheetEdit from "./wmsSheetEdit"
import submenu from "@/components/submenu"

import { pageWmsSheet, detailWmsSheet, delWmsSheet, batchDeleteWmsSheet } from "@/api/wms/wmsSheet"
import { getEnums } from "@/api/common"

let moment = require('moment')
Vue.prototype.Clipboard = Clipboard

export default {
  name: 'WmsSheet',
  components: { submenu, passWord, sheetAdd, sheetDetail, sheetEdit },
  data() {
    return {
      searchForm: {
      },
      total: 0,
      page: 1,
      counts: 0,
      pageSize: 20,
      tableData: [],
      backImg: backImg,
      enums: {
        WmsSheetTypeEnum: [],
      },
      enumMaps: {},
      loading: false,
      multipleSelection: [],
    }
  },
  methods: {
    initEnums() {
        const param = {
            names: Object.keys(this.enums)
        }
        getEnums(param).then(res=>{
            if(res.code===200) {
              this.enums = res.data.enums
              Object.keys(this.enums).forEach(key => {
                let map = {}
                this.enums[key].forEach(obj => map[obj.value] = obj.name)
                this.enumMaps[key] = map
              })
            } else {
                this.$message.error(res.message)
            }
        })
    },
    handleCommand(arg) {
        console.log("handleCommand", arg)
        this.multipleSelection = this.$refs.table.getCheckboxRecords()
        if(arg == "batchDelete") {
            return this.handleBatchDelete()
        }
    },
    handleBatchDelete() {
        console.log("handleBatchDelete", this.multipleSelection)
        if(this.multipleSelection.length ==0) {
            return this.$message.warning("请选择要批量删除的记录")
        }
        this.$confirm('确定要删除这'+ this.multipleSelection.length +'条数据吗，一旦删除不可恢复，请谨慎操作?', '温馨提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
              }).then(() => {
                 const param = {
                    ids: this.multipleSelection.map(it => it.id)
                 }
                 batchDeleteWmsSheet(param).then(res => {
                    if(res.code===200) {
                        this.$message.success(res.message)
                        this.getTopSearch()
                    } else {
                        this.$message.error(res.message)
                    }
                 })
        }).catch(err=>{})
    },
    handleAdd() {
      this.$refs.wmsSheetAdd.onOpen()
    },
    handleDetail(row) {
      this.$refs.wmsSheetDetail.onOpen(row)
    },
    handleEdit(row) {
      this.$refs.wmsSheetEdit.onOpen(row)
    },
    handleSelectionChange(val) {
        console.log("handleSelectionChange", val)
      this.multipleSelection = val.records
    },
    del(row) {
      const param = {
          id: row.id
      }
      del(param).then(res=>{
          if(res.code===200) {
            this.$message.success(res.message)
            this.getTopSearch()
          } else {
            this.$message.error(res.message)
          }
      })
    },
    handleSizeChange(val){
      this.pageSize = val
      this.getTopSearch()
    },
    createSearch() {
      this.page = 1
      this.getTopSearch()
    },
    reset(){
      this.searchForm={
        date: ''
      }
      this.page = 1
      this.getTopSearch()
    },

    getTopSearch(){
      var that = this
      var param = that.searchForm
      param.pageNo = that.page
      param.pageSize = that.pageSize
      param.orderByCreated = "DESC"
      that.loading = true
      pageWmsSheet(param).then(res => {
        that.loading = false
        if(res.code ===200) {
          var nodataArr = res.data.rows || []
          that.tableData = nodataArr
          that.total = res.data.totalRows
          that.counts = res.data.counts
        }else{
          this.$message.error(res.message)
        }
      }).catch(err=>{
        that.loading = false
      })
    },
    changePage (newPage) {
      var that = this
      if (that.page === newPage) {
        return
      }
      that.page = newPage
      that.getTopSearch()
    },
  },
  created() {
    this.initEnums()
    this.getTopSearch()
  },
  mounted(){
  }
}
</script>
<style scoped>
.el-pagination.is-background .el-pager li:not(.disabled).active{
  background-color: #07C160;
  color: #FFF;
}
.el-tabs__nav-wrap::after{
  height: 1px;
}
.el-form-item__label{
  font-size: 12px;
}
.el-select {
    width: 190px;
}
.red {
  color: rgb(236, 78, 15);
}
.c-button{
  margin-left:8px;
}
.tbBtn {
  margin-right:10px;
}
.buttons {
}
</style>